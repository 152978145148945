.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url('../../assets/background_red.jpg');
    background-size: cover;
    color: rgb(250, 250, 250);
    padding: 20px;
}

.social{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
}

.logo{
    height: 80px;
}

.footer small{
    margin-top: 20px;
}

.social-buttons{
    display: flex; 
}

.social-buttons img{
    height: 30px;
    filter: invert(99%) sepia(2%) saturate(459%) hue-rotate(188deg) brightness(122%) contrast(100%);
}

.social a{
    display: flex;
    margin: 0px 5px;
}