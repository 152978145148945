@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,500;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
}

body{
    background: #FFF;
    font: 400 15px Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
}

input, button { 
    border: none;
    background: none;
}

ul {
    list-style: none;
}

button{
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
::-webkit-scrollbar-track {
    background: #dfdfdf;
  }
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;       /* roundness of the scroll thumb */
  }
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
  }