.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content{
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    width: 100%;
    justify-content: center;
    color:rgb(24, 24, 24);
    align-items: center;
    text-align: justify;
}

.colorized{
    background-color: rgb(248, 248, 248);
}

.colorized svg{
    height: 18px;
    margin-bottom: -4px;
    margin-right: 5px;
    color: #AF241D;

}
.content h3{
    margin-bottom: 25px;
    font-size: 18px;
}

.content p{
    margin-top: 20px;
    max-width: 800px;
}

.content strong{
    color: #AF241D;
}

.test{
    width: 100%;
    border: 0;
    min-height: 100vh;
    margin-bottom: 30px;
}

/* Arrow */
.arrow {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 20px;
}

.arrow img{
    height: 50px;
    filter: invert(30%) sepia(32%) saturate(2688%) hue-rotate(331deg) brightness(84%) contrast(115%);
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}